<template>
	<!--eslint-disable-->
	<ValidationObserver ref="form" v-slot="{ handleSubmit }">
		<form class="form contract-form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
			<hr style="margin-top: -26px;">
			<div class="row">
				<div class="col-md-6 mb-2">
					<div class="form-group">
						<label class="form-label col-form-label">Họ và tên <span class="text-danger">*</span></label>
						<ValidationProvider vid="name" name="Họ và tên" rules="required" v-slot="{ errors }">
							<el-input disabled v-model="user.name" filterable class="w-100" placeholder="Họ và tên"></el-input>
							<div class="fv-plugins-message-container">
								<div data-field="name" data-validator="notEmpty" class="fv-help-block">
									{{ errors[0] }}
								</div>
							</div>
						</ValidationProvider>
					</div>
				</div>
				<div class="col-md-6 mb-2">
					<div class="form-group">
						<label class="form-label col-form-label">Điện thoại <span class="text-danger">*</span></label>
						<el-input v-model="user.phone" filterable class="w-100" placeholder="Số điện thoại" disabled></el-input>
					</div>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-md-6">
					<!-- Thông tin đơn hàng-->
					<p class="font-weight-bold h3">Thông tin đơn hàng</p>
					<div class="form-group">
						<label class="form-label col-form-label">Trung tâm đăng ký học<span class="text-danger">*</span></label>
						<el-input v-model="user.center_contract" filterable class="w-100" placeholder="Trung tâm"
											disabled></el-input>
					</div>
					<div class="form-group">
						<label class="form-label col-form-label">Chi nhánh học tập<span class="text-danger">*</span></label>
						<el-input v-model="user.branch_contract" filterable class="w-100" placeholder="Chi nhánh"
											disabled>

						</el-input>
					</div>
					<!--     Danh sách khóa học     -->
					<div class="row row-course form-group" v-for="(item,key) in courses.data" :key="key">
						<div class="col-md-12">
							<div class="form-group row">
								<div class="col-md-5">
									<label class="form-label">Khóa học {{ key + 1 }} <span class="text-danger">*</span></label>
								</div>
								<div class="col-md-6">
									<multiselect
										v-model="item.model_course"
										:options="options"
										:searchable="false"
										:disabled="isWithDrawCourse(item, key)"
										:close-on-select="true"
										:allow-empty="true"
										:select-label="'Chọn'"
										:deselect-label="'Bỏ'"
										placeholder="Chọn khóa học"
										:show-labels="false"
										track-by="id"
										label="name"
										@input="onChangeCourse(item, $event)"
									>
									</multiselect>
								</div>
								<div class="col-md-1 mb-8 cursor-pointer" @click="removeCourseSelect(key)">
									<i class="el-icon-delete mt-2 font-weight-bold text-danger fa-lg"
										 v-if="key > 0 && !checkView || !isWithDrawCourse(item, key)"></i>
								</div>
								<span class="badge badge-danger withdraw-course" v-if="isWithDrawCourse(item, key)">Đã hủy</span>
							</div>
						</div>
					</div>
					<div class="row">
						<el-button v-if="!checkView" @click="addNewCourse()" size="mini" type="primary" icon="el-icon-plus">Thêm
							khóa
						</el-button>
					</div>
					<div class="row mt-3" v-if="!checkView">
						<!--Mã giảm giá-->
						<div class="col-md-12 d-flex justify-content-end">
							<el-badge :value="numberGift" class="item" type="success">
								<el-button @click="openListGift()" size="small">Chọn quà tặng</el-button>
							</el-badge>
							<el-badge :value="numberVoucher" class="item ml-3" type="success">
								<el-button @click="openListVoucher()" size="small"> Chọn voucher</el-button>
							</el-badge>
						</div>
					</div>
					<div class="promotions row mt-4" v-for="(item, index) in list_promotions_selected"
							 v-if="list_promotions_selected.length > 0">
						<div class="col-md-5 font-weight-bold">
							<span>{{ item.name }}</span>
							<span v-if="item.type == 0">(Voucher)</span>
							<span v-else>(Mã khuyến mại)</span>

						</div>
						<div class="col-md-6 font-weight-bold">
              <span v-if="item.unit === 0">
                <span class="badge badge-success"> {{ item.value }}%</span>
              </span>
							<span v-else>
                <span class="badge badge-success">{{ formatPrice(item.value_money) }}đ</span>
              </span>
						</div>
						<div class="col-md-1">
							<i class="el-icon-delete mt-2 font-weight-bold text-danger fa-lg cursor-pointer"
								 @click="unPickPromotions(index)"></i>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="col-md-12">
						<div class="form-group">
							<div class="seleted-course">
								<p class="font-weight-bold h3">Chi tiết thanh toán</p>
								<p class="font-weight-bold h5">Danh sách khóa học</p>
								<div v-for="(item, index) in courses.data" class="d-flex justify-content-between" :key="index">
									<p><i class="el-icon-menu mr-2 text-info"></i>{{ item.name }}</p>
									<p>{{ formatPrice(item.price - (item.price_sale * item.price / 100)) }} đ</p>
								</div>
							</div>
							<div class="payment mt-4">
								<div class="d-flex justify-content-between">
									<div class="name">Tổng giá gốc</div>
									<div class="price-course">{{ formatPrice(total) }} đ</div>
								</div>
								<div class="d-flex justify-content-between mt-3">
									<div class="name">Tổng khuyến mại</div>
									<div class="price-course">-{{ formatPrice(total_promotions) }} đ</div>
								</div>
								<div class="font-weight-bold h5 d-flex justify-content-between mt-3">
									<div class="name">Tổng thanh toán</div>
									<div class="price-course">{{ formatPrice(total_need) }} đ</div>
								</div>
								<div class="gift" v-if="gift_select?.description">
									<p class="font-weight-bold h4">Quà tặng</p>
									<el-input
										:value="gift_select?.description"
										autosize
										type="textarea"
										placeholder="Quà tặng"
										style="white-space: pre;"
										disabled = "true"
									/>

								</div>
								<p class="font-weight-light font-italic">Lưu ý: Giá trị hợp đồng chỉ có giá trị tại thời điểm hiện tại.
									Giá khóa học
									và khuyến mại có thể
									thay đổi theo thời gian.</p>
								<!--                <div class="course-detail last">-->
								<!--                  <div class="name">Khách hàng nộp</div>-->
								<!--                  <div class="price-course">{{ formatPrice(payment.cash) }} đ</div>-->
								<!--                </div>-->
								<!--                <div class="course-detail">-->
								<!--                  <div class="name">Nợ</div>-->
								<!--                  <div class="price-course">{{ formatPrice(payment.debt) }} đ</div>-->
								<!--                </div>-->
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row" v-if="check_send_code">
				<div class="col-md-6">
					<div class="form-group row">
						<label class="col-md-3 form-label col-form-label">Mã xác thực <span class="text-danger">*</span></label>
						<div class="col-md-7">
							<input type="text" class="form-control" id="user_phone" placeholder="Mã xác thực" v-model="user.pin_code">
						</div>
						<div class="col-md-2" style="padding-right: 0.2rem;">
							<a class="btn btn-primary" style="color: white; min-width: 64px;" @click="verifyPhone()">Gửi</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row total-row-course">
				<div class="col-md-12 action-button d-flex justify-content-end">
					<!--              <button class="btn btn-register create-contract" @click="createContract()"-->
					<!--                      v-if="!checkView && !checkEdit" :disabled="check_disabled">Đăng ký-->
					<!--              </button>-->
					<!--              <button class="btn btn-register update-contract" @click="updateContract()" v-if="checkEdit"-->
					<!--                      :disabled="check_disabled_edit">Cập nhật-->
					<!--              </button>-->
					<button class="btn btn-primary" @click="close()">Đóng</button>
				</div>
			</div>
		</form>
		<el-dialog
			id="promotion"
			title="Danh sách mã khuyến mại"
			:visible.sync="comboDialogVisible"
			center
			style="right: 12px !important;"
			append-to-body
		>
			<div class="list-code-container">
				<div v-if="listPromotion.length > 0">
					<div
						class="row row-promotion"
						v-for="item in listPromotion"
						:key="item.id"
						:v-if="item.id"
					>
						<div class="col-md-3 voucher-image">
							<img style="width: 120px; height: 75px; position: relative;" :src="combo_image"/>
						</div>
						<div class="col-md-3 voucher-detail">
							<span>Tên: {{ item.name }}</span>
							<br>
							<span class="discount" v-if="item.unit == 1">
              Giảm ngay:
              {{ formatPrice(item.value) }}
            </span>
							<span class="discount" v-if="item.unit == 0">
              Giảm ngay:
              {{ item.value }} %
            </span>
							<br>
							<span class="end_date" v-if="item.end_date"> Ngày hết hạn: {{ item.end_date }} </span>
							<span class="end_date" v-if="!item.end_date"> Chưa có thời hạn </span>
							<br>
							<button @click="showDetailPromotion(item)" class="btn btn-success btn-xs"><i
								class="fas fa-info-circle"></i> Xem chi tiết
							</button>
						</div>
						<div class="col-md-3 voucher-action text-center">
							<el-button type="primary" :disabled="checkDisable(item.id)" @click="pickPromotions(item)">Áp dụng
							</el-button>
						</div>
					</div>
				</div>
				<div v-else class="no-promotion">
					<p>Không có mã khuyến mại</p>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			id="voucher"
			title="Danh sách voucher"
			:visible.sync="voucherDialogVisible"
			center
			style="right: 12px !important;"
			append-to-body
		>
			<hr style="margin-top: -26px;">
			<div class="list-code-container">
				<div class="row search-voucher" style="margin-bottom: 30px">
					<div class="col-md-12">
						<el-input v-model="searchVoucher" @keyup.native="searchVoucherByName()"
											placeholder="Nhập tên voucher"></el-input>
					</div>
				</div>
				<div v-if="listVoucher.length > 0">
					<div
						class="row row-promotion"
						v-for="voucher in listVoucher"
						:key="voucher.id"
						:v-if="voucher.id"
					>
						<div class="col-md-3 voucher-image">
							<img style="width: 120px; height: 85px; position: relative;" :src="voucher_image"/>
						</div>
						<div class="col-md-3 voucher-detail">
							<span>Tên: {{ voucher.name }}</span>
							<br>
							<span class="discount" v-if="voucher.unit == 1">
              Giảm ngay:
              {{ formatPrice(voucher.value) }}
            </span>
							<span class="discount" v-if="voucher.unit == 0">
              Giảm ngay:
              {{ voucher.value }} %
            </span>
							<br>
							<span class="end_date" v-if="voucher.end_date"> Hiệu lực đến: {{ voucher.end_date }} </span>
							<span class="end_date" v-if="!voucher.end_date"> Chưa có thời hạn </span>
							<br>
							<button @click="showDetailPromotion(voucher)" class="btn btn-success btn-xs"><i
								class="fas fa-info-circle"></i> Xem chi tiết
							</button>
						</div>
						<div class="col-md-3 voucher-action">
							<el-button type="button" id="btn_select_voucher"
												 @click="pickPromotions(voucher)"
												 :disabled="voucher.is_selected ? true : false"
												 :class="{'voucher_selected': voucher.is_selected}"
							>
								Áp dụng
							</el-button>
						</div>
					</div>
				</div>
				<div v-else class="no-promotion">
					<p>Không có voucher</p>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			:title="promotionDetail.type == 0 ? 'Chi tiết voucher' : 'Chi tiết combo'"
			:visible.sync="detailPromotionDialogVisible"
			width="40%"
			center
			style="right: 12px !important"
			append-to-body
		>
			<div class="row row-detail">
				<div class="col-lg-3"><i class="fas fa-info-circle"></i> Tên :</div>
				<div class="col-lg-8 col-lg-offset-1">{{ promotionDetail.name }}</div>
			</div>
			<hr>
			<div class="row row-detail">
				<div class="col-lg-3"><i class="fas fa-money-bill"></i> Giảm ngay :</div>
				<div class="col-lg-8 col-lg-offset-1">
					<span v-if="promotionDetail.unit === 1"><b> {{ formatPrice(promotionDetail.value) }} </b> VNĐ </span>
					<span v-if="promotionDetail.unit === 0"><b> {{ promotionDetail.value }} </b> % </span>
				</div>
			</div>
			<hr>
			<div class="row row-detail">
				<div class="col-lg-3"><i class="fas fa-calendar"></i> Ngày bắt đầu :</div>
				<div class="col-lg-8 col-lg-offset-1">{{ promotionDetail.start_date ? promotionDetail.start_date : '' }}</div>
			</div>
			<hr>
			<div class="row row-detail">
				<div class="col-lg-3"><i class="fas fa-calendar"></i> Ngày kết thúc :</div>
				<div class="col-lg-8 col-lg-offset-1">{{ promotionDetail.end_date ? promotionDetail.end_date : '' }}</div>
			</div>
			<hr v-if="promotionDetail.type === 0">
			<div class="row row-detail" v-if="promotionDetail.type === 0" style="border-bottom: none">
				<div class="col-lg-3"><i class="fas fa-eye"></i>Mô tả :</div>
				<div v-html="promotionDetail.description" class="col-lg-8 col-lg-offset-1">{{ promotionDetail.description }}
				</div>
			</div>
		</el-dialog>
		<el-dialog
			id="gift"
			title="Chọn quà tặng"
			:visible.sync="giftDialogVisible"
			center
			style="right: 12px !important;"
			append-to-body

		>
			<div class="list-code-container">
				<el-input class="container mb-3" placeholder="Nhập tên quà tặng" @input="searchGift()"
									v-model="dataSearchGift"/>
				<div v-if="listGift.length > 0">
					<div

						v-for="(item, index) in listGift"
						:key="'c'+index"
						:v-if="item.id"

					>
						<div class="border border-info mb-3" style="background-image: linear-gradient(to bottom, #DFEAFF,#FFFFFF)">
							<GiftDialog
								:item="item"
								:listPromotions="list_promotions_selected"
								:giftSelect="gift_select"
								@pickPrice="pickPromotions(item)"
								@pickGift="pickGift(item)"
								@showDetailPromotion="showDetailPromotion(item)"
								@cancelGift="cancelGift(item)"
							></GiftDialog>
						</div>
					</div>
				</div>
				<div v-else class="no-promotion mt-2">
					<p>Không có quà tặng</p>
				</div>
			</div>
		</el-dialog>

	</ValidationObserver>

</template>

<script>
import {mapGetters} from "vuex";
import {
	LIST_COURSE_BY_CENTER,
	LIST_CLASSROOM_BY_COURSE
} from "@/core/services/store/contract/contract.module";
import Swal from 'sweetalert2';
import Multiselect from "vue-multiselect";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {
	CHECK_UNIQUE_EMAIL, CREATE_CONTRACT, FIND_CONTRACT, FIND_PROMOTION, GET_COURSE_CONTRACT,
	GET_INFO_USER, LIST_COMBO, LIST_GIFT, LIST_VOUCHER,
	SEND_CODE_VERIFY, UPDATE_CONTRACT, VERIFY_PHONE
} from "../../../core/services/store/contract/contract.module";
import {GET_CENTER_BY_ID} from "@/core/services/store/center/center.module";
import {GET_BRANCH_BY_ID} from "@/core/services/store/center/branch.module";
import GiftDialog from "./components/GiftDialog";

const _ = deepdash(lodash);
export default {
	props: {
		customerId: {
			default: null
		},
		centerId: {
			default: null
		},
		branchId: {
			default: null
		},
		contract: {
			default: null
		},
		checkEdit: {
			default: false
		},
		checkView: {
			default: false
		},
		data: Object,
	},
	components: {
		Multiselect,
		GiftDialog
	},
	data() {
		return {
			combo_image: `${process.env.VUE_APP_BASE_URL}media/svg/combo.svg`,
			voucher_image: `${process.env.VUE_APP_BASE_URL}media/svg/voucher.svg`,
			check_show: false,
			defaultDate: null,
			pickerOptions: {
				disabledDate: this.disabledDate,
			},
			loading: {
				save: false,
				loadingSelect: false
			},
			ngay_hen: null,
			time_start_work: null,
			parent: null,
			centers: [],
			appointment: {},
			branches: [],
			delete_course_image: `${process.env.VUE_APP_BASE_URL}media/svg/delete.svg`,
			image_verify_phone: `${process.env.VUE_APP_BASE_URL}media/svg/alert-circle.svg`,
			courses: {
				data: [],
			},
			classrooms: {
				'0': [],
			},
			options: [],
			list_promotions_selected: [],
			payment: {
				cash: 0,
				debt: 0,
			},
			total: 0,
			total_need: 0,
			total_promotions: 0,
			checkInputEmail: false,
			checkUniqueEmail: false,
			user: {
				id: '',
				name: '',
				phone: '',
				email: '',
				birthday: '',
				pin_code: '',
				center_contract: '',
				branch_contract: ''
			},
			verify_phone: '',
			check_verify_success: false,
			check_send_code: false,
			check_sendOTP: false,
			check_disabled: false,
			comboDialogVisible: false,
			voucherDialogVisible: false,
			listPromotion: [],
			listVoucher: [],
			listGift: [],
			searchVoucher: '',
			filterListVoucher: [],
			promotionDetail: [],
			detailPromotionDialogVisible: false,
			numberCombo: 0,
			numberVoucher: 0,
			check_disabled_edit: false,
			withDrawCourses: [],
			giftDialogVisible: false,
			dataSearchGift: null,
			gift_select: null,
			gift_id: []
		}
	},
	async mounted() {
		await this.setUp();
		await this.getCenterById();
		await this.getBranchById();
		this.getListGift();
	},
	methods: {
		getCenterById() {
			let center_id = this.$route.query.center_id;
			if (center_id) {
				this.$store.dispatch(GET_CENTER_BY_ID, center_id).then((data) => {
					this.user.center_contract = data.data.name;
				})
			}
		},
		getBranchById() {
			let branch_id = this.$route.query.branch_id;
			if (branch_id) {
				this.$store.dispatch(GET_BRANCH_BY_ID, branch_id).then((data) => {
					this.user.branch_contract = data.data.name;
				})
			}
		},
		async setUp() {
			this.findUser();
			if (this.ngayHenOld) {
				this.appointment.ngay_hen = this.$moment(this.ngayHenOld).format('yyyy-MM-DD HH:mm');
			} else {
				this.appointment.ngay_hen = this.$moment().format('yyyy-MM-DD HH:mm');
			}
			if (this.contract) {
				this.fetchCourseByCenter(this.contract.center_id, this.contract.branch_id);
				this.findContract();
			} else {
				let listClassrooms = [];
				listClassrooms[0] = [];
				this.fetchCourseByCenter(this.$route.query.center_id, this.branchId);
				//tạo hợp đồng theo combo/khóa học/lớp học
				let promotion = this.$route.query.promotion_id;
				let classroom_id = this.$route.query.classroom_id;
				let course_id = this.$route.query.course_id;
				let branch_id = this.$route.query.branch_id;
				let data_send = {};
				if (promotion || classroom_id || course_id) {
					let listCoursePromotion = [];
					let classroom_select = null;
					if (promotion) {
						data_send = {promotion_id: promotion}
					} else if (parseInt(classroom_id)) {
						data_send = {classroom_id: classroom_id}
					} else {
						data_send = {course_id: course_id, branch_id: branch_id}
					}
					await this.$store.dispatch(GET_COURSE_CONTRACT, data_send)
						.then((response) => {
							_.forEach(response.data, function (value) {
								let classroom = [];
								_.forEach(value.classes, function (cl) {
									classroom.push({id: cl.id, name: cl.name});
									if (classroom_id && classroom_id == cl.id) {
										classroom_select = {id: classroom_id, name: cl.name}
									}
								});
								listClassrooms[value.id] = value.classes;
								listCoursePromotion.push({
									id: value.id,
									name: value.name,
									course_id: value.id,
									classroom_id: null,
									price: value.fee,
									price_sale: value.sale,
									classroom: classroom_select,
									model_course: {id: value.id, name: value.name}
								});
							});
						});
					this.courses.data = listCoursePromotion;
					this.classrooms = listClassrooms;
					await this.getListCombo(this.centerId, this.branchId);
					await this.getListVoucher(this.centerId, this.branchId);
				} else {
					this.classrooms = {0: []};
					this.courses.data.push({
						id: 0,
						text: "0",
						course_id: 0,
						classroom_id: null,
						price: 0,
						price_sale: 10,
						classroom: []
					});
				}
				await this.calculatorTotal();
			}
		},
		checkDisable(id) {
			for (let i = 0; i < this.list_promotions_selected.length; i++) {
				let item = this.list_promotions_selected[i];
				if (item.id === id) {
					return true;
				}
			}
		},
		disabledDate(date) {
			let tempDate = new Date();
			let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0);
			let extentDate = '';
			if (this.ngayHenOld) {
				extentDate = this.$moment(this.ngayHenOld).subtract(1, 'day');
			} else {
				extentDate = this.$moment().add(2, 'days');
			}
			return date < currentDate || date > extentDate;
		},
		close() {
			this.$emit('close')
		},
		checkEmail(email) {
			var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
			if (!pattern.test(email)) {
				this.checkInputEmail = true;
			} else {
				this.checkInputEmail = false;
				this.$store.dispatch(CHECK_UNIQUE_EMAIL, {email: email, user_id: this.user.id})
					.then((response) => {
						if (response.error) {
							this.checkUniqueEmail = true
						} else {
							this.checkUniqueEmail = false
						}
					});
			}
		},
		removeCourseSelect: function (index) {
			this.courses.data.splice(index, 1);
			let center_id = this.centerId;
			let branch_id = this.branchId;
			if (this.contract) {
				center_id = this.contract.center_id;
				branch_id = this.contract.branch_id;
			}
			this.getListCombo(center_id, branch_id);
			this.calculatorTotal();
		},
		async fetchCourseByCenter(center_id, branch_id) {
			this.$store.dispatch(LIST_COURSE_BY_CENTER, {center_id: center_id, branch_id: branch_id})
				.then((response) => {
					if (response.status === 422) {
						this.$notify({
							title: 'Warning',
							message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
							type: 'warning'
						});
					} else {
						let listCourse = response.data;
						let listCourseConvert = [];
						let listClassrooms = [];
						listClassrooms[0] = [];
						_.forEach(listCourse, function (value) {
							listClassrooms[value.id] = value.classes;
							listCourseConvert.push({
								id: value.id,
								name: value.name,
								course_id: value.id,
								price: value.price,
								price_sale: value.price_sale
							})
						});

						this.options = listCourseConvert;
						this.classrooms = listClassrooms;
					}
				});
		},
		addNewCourse: function () {
			this.courses.data.push({
				id: 0,
				text: "Chọn khóa học",
				course_id: 0,
				classroom_id: 0,
				price: 0,
				price_sale: 0,
				classroom: {}
			});
		},
		formatPrice(value) {
			let val = (value / 1).toFixed(0).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
		},
		async onChangeCourse(course, event) {
			if (event.id) {
				if (this.courses.data.some(e => e.id === event.id)) {
					this.courses.data.splice(-1)
					Swal.fire(
						'Khóa học đã được chọn',
						'',
						'warning'
					);
				} else {
					let center_id = this.centerId;
					let branch_id = this.branchId;
					if (this.contract) {
						center_id = this.contract.center_id;
						branch_id = this.contract.branch_id;
					}
					let data = await this.$store.dispatch(LIST_CLASSROOM_BY_COURSE, {
						id: event.id,
						branch_id: branch_id
					});
					this.classrooms[event.id] = data.data;
					course.id = event.id;
					course.name = event.name;
					course.course_id = event.id;
					course.price = event.price;
					course.price_sale = event.price_sale;
					course.classroom_id = 0;
					course.classroom = [];
					this.list_promotions_selected = [];
					this.calculatorTotal();
					this.getListCombo(center_id, branch_id);
					this.$forceUpdate();
				}
			}
		},
		calculatorTotal() {
			this.total = 0;
			this.courses.data.map((value) => {
				this.total += (value.price - (value.price_sale * value.price / 100));
			});
			this.total_need = this.total;
			var value_promotions_total = 0;
			this.list_promotions_selected.map((promo) => {
				if (promo.unit == 1) {
					value_promotions_total += promo.value;
					promo.value_money = promo.value;
				} else {
					value_promotions_total += this.total * promo.value / 100;
					promo.value_money = this.total * promo.value / 100;
				}
			});
			console.log(value_promotions_total)
			this.total_promotions = value_promotions_total;
			this.total_need = this.total - value_promotions_total;
			this.payment.debt = this.total_need - this.payment.cash;
		},
		pickPromotions(event) {
			this.comboDialogVisible = false;
			this.voucherDialogVisible = false;
			this.value_promotion = event.value;
			this.promotion_id = event.id;
			this.promotion_name = event.name;
			this.promotion_unit = event.unit;
			let temp = this.list_promotions_selected;
			_.each(temp, function (value, key) {
				if (event.type === 1 || event.type === 2) {
					if (value.type === 1 || value.type === 2) {
						temp.splice(key, 1);
					}
				}
			});
			temp.push(event);
			this.list_promotions_selected = temp;
			this.calculatorTotal();
			this.check_promotion = true;
			this.checkVoucherSelected();
		},
		unPickPromotions(index) {
			this.list_promotions_selected.splice(index, 1);
			this.checkVoucherSelected();
			this.calculatorTotal();
			this.check_promotion = false;
		},
		checkVoucherSelected() {
			let temp = this.list_promotions_selected;
			this.listVoucher.map(function (item) {
				let index_selected = temp.findIndex(e => e.id === item.id);
				if (index_selected >= 0) {
					item.is_selected = 1;
				} else {
					item.is_selected = 0;
				}
			});
		},
		onChangeClassroom(course, event) {
			if (event.id) {
				course.classroom_id = event.id;
				this.$forceUpdate();
			}
		},
		findUser() {
			let customer_id = 0;
			if (this.$route.query.customer_id) {
				customer_id = this.$route.query.customer_id;
			} else {
				customer_id = this.customerId;
			}
			this.$store.dispatch(GET_INFO_USER, {customer_id: customer_id})
				.then((response) => {
					this.user.id = response.data.user.id ?? '';
					this.user.name = response.data.user.name ?? '';
					this.user.email = response.data.user.email ?? '';
					this.user.phone = response.data.user.phone ?? '';
					this.user.birthday = response.data.user.profile.birthday ?? '';
					if (response.data.user.profile.xac_thuc_sdt) {
						this.check_verify_success = true;
					}
				});
		},
		sendCodeVerify() {
			this.check_sendOTP = true;
			this.$store.dispatch(SEND_CODE_VERIFY, {phone: this.user.phone})
				.then((response) => {
					if (response.data.status == "error") {
						this.check_sendOTP = false;
						Swal.fire(
							`${response.data.message}`,
							'',
							'error'
						);
					} else {
						Swal.fire(
							'Gửi mã xác thực thành công',
							'',
							'success'
						).then((result) => {
							this.check_send_code = true;
						});
					}
				});
		},
		verifyPhone() {
			this.$store.dispatch(VERIFY_PHONE, {
				phone: this.user.phone,
				user_id: this.user.id,
				pin_code: this.user.pin_code
			})
				.then((response) => {
					if (response.error) {
						Swal.fire(
							'Mã xác thực không đúng hoặc đã hết hạn',
							'',
							'error'
						);
					} else {
						Swal.fire(
							'Xác thực số điện thoại thành công',
							'',
							'success'
						).then(() => {
							this.check_send_code = false;
							this.check_verify_success = true;
						});
					}
				});
		},
		createContract() {
			if (this.checkInputEmail || this.checkUniqueEmail) {
				return false;
			}
			if (this.user.name == '' || this.user.email == '' || this.user.birthday == '') {
				Swal.fire(
					'Hãy nhập đầy đủ họ tên, email và ngày sinh của khách hàng',
					'',
					'warning'
				);
				return false;
			}
			this.check_disabled = true;
			let customer_id = this.$route.query.customer_id;
			let promotion_url = this.$route.query.promotion_id;
			let classroom_url = this.$route.query.classroom_id;
			let branch_id = this.$route.query.branch_id;
			let payment = this.$route.query.payment;
			let courseSend = [];
			let data_send = {};
			_.each(this.courses.data, function (course) {
				if (course.id > 0) {
					courseSend.push({
						"course": course.id,
						"price": course.price,
						"price_sale": course.price_sale,
						"classroom": '',
					})
				}
			});
			let promotions = [];
			_.each(this.list_promotions_selected, function (promotion) {
				promotions.push(promotion.id);
			});
			data_send = {
				'user_info': this.user,
				'courses': courseSend,
				'amount': this.payment.cash,
				'customer_id': customer_id,
				'promotions': promotions,
				'promotion_url': promotion_url,
				'classroom_url': classroom_url,
				'branch_id': branch_id,
				'payment': payment
			};
			this.$store.dispatch(CREATE_CONTRACT, data_send).then((response) => {
				if (response.error) {
					this.check_disabled = false;
					Swal.fire(
						`${response.data.message}`,
						'',
						'error'
					);
				} else {
					this.check_disabled = true;
					Swal.fire(
						'Tạo hợp đồng thành công',
						'',
						'success'
					).then(() => {
						this.$emit('completed', {
							user_name: response.data.user_name,
							created_at: response.data.created_at,
							id: response.data.id,
							total_paid: response.data.total_paid,
							center_id: response.data.center_id,
							branch_id: response.data.branch_id
						});
					});
				}
			});
		},
		async findContract() {
			let listClassrooms = [];
			listClassrooms[0] = [];
			let listCoursePromotion = [];
			await this.$store.dispatch(FIND_CONTRACT, this.contract.id).then((response) => {
				_.forEach(response.data.student_register_courses, function (value) {
					let classroom = [];
					let course_class = null;
					_.forEach(value.course.classes, function (value_cl) {
						classroom.push({id: value_cl.id, name: value_cl.name})
					});
					if (value.classes) {
						course_class = {id: value.classes.id, name: value.classes.name};
					}
					listClassrooms[value.course.id] = value.course.classes;
					listCoursePromotion.push({
						id: value.course.id,
						name: value.course.name,
						course_id: value.course.id,
						classroom_id: null,
						price: value.course_fee,
						price_sale: value.course_sale,
						classroom: course_class,
						model_course: {id: value.course.id, name: value.course.name}
					});
				});
				this.payment.cash = response.data.total_paid;
				this.payment.debt = response.data.total_debt;
				this.value_promotion = 0;
				this.promotion_name = '';
				this.check_promotion = false;
				if (response.data.promotion_many) {
					this.list_promotions_selected = response.data.promotion_many;
					this.check_promotion = true;
				}
				let userInfo = response.data.user ?? '';
				this.user.name = userInfo.name ?? '';
				this.user.email = userInfo.email ?? '';
				this.user.phone = userInfo.phone ?? '';
				this.user.birthday = userInfo.profile.birthday ? userInfo.profile.birthday : '';
				let infoCenter = response.data.center ?? '';
				this.user.center_contract = infoCenter.name ?? '';
				let infoBranch = response.data.branch ?? '';
				this.user.branch_contract = infoBranch.name ?? '';
				if (response.data.subcontracts.length) {
					response.data.subcontracts.map((item) => item.courses_relation.map(v => v.id)).forEach(c => {
						this.withDrawCourses = this.withDrawCourses.concat(c)
					});
				}
				this.$forceUpdate();
			});
			this.courses.data = listCoursePromotion;
			this.classrooms = listClassrooms;
			this.calculatorTotal();
			await this.getListCombo(this.contract.center_id, this.contract.branch_id);
			await this.getListVoucher(this.contract.center_id, this.contract.branch_id);
		},
		updateContract() {
			this.check_disabled_edit = true
			let courseSend = [];
			let promotions = [];
			_.each(this.courses.data, function (course) {
				courseSend.push({
					"course": course.id,
					"price": course.price,
					"price_sale": course.price_sale,
					"classroom": course.classroom ? course.classroom.id : '',
				})
			});
			_.each(this.list_promotions_selected, function (promotion) {
				promotions.push(promotion.id);
			});
			let data_send = {
				'id': this.contract.id,
				'courses': courseSend,
				'promotions': promotions,
			};
			this.$store.dispatch(UPDATE_CONTRACT, data_send).then((response) => {
				if (response.data.error) {
					this.check_disabled_edit = false
					Swal.fire(
						`${response.data.message}`,
						'',
						'error'
					);
				} else {
					Swal.fire(
						'Sửa hợp đồng thành công',
						'',
						'success'
					).then(() => {
						this.check_disabled_edit = false
						this.$emit('completed', {});
					});
				}
			});
		},
		openListCombo() {
			this.comboDialogVisible = true;
			this.getListCombo(this.centerId, this.branchId);
		},
		async getListCombo(center_id, branch_id) {
			let courseSend = [];
			_.each(this.courses.data, function (course) {
				courseSend.push(course.id);
			});
			await this.$store.dispatch(LIST_COMBO, {
				'courses': courseSend,
				'center_id': center_id,
				'branch_id': branch_id
			}).then((response) => {
				this.listPromotion = response.data;
				this.numberCombo = this.listPromotion.length;
			});
		},
		async openListVoucher() {
			this.voucherDialogVisible = true;
			this.searchVoucher = '';
			await this.$store.dispatch(LIST_VOUCHER, {
				'name': this.searchVoucher,
				'branch_id': this.branchId,
				'center_id': this.centerId
			}).then((response) => {
				this.listVoucher = response.data;
				this.numberVoucher = this.listVoucher.length;
				this.checkVoucherSelected();
			});
			await this.checkVoucherSelected();
		},
		getListVoucher(center_id, branch_id) {
			this.$store.dispatch(LIST_VOUCHER, {
				'name': this.searchVoucher,
				'branch_id': branch_id,
				'center_id': center_id
			}).then((response) => {
				this.listVoucher = response.data;
				this.numberVoucher = this.listVoucher.length;
			});
		},
		searchVoucherByName() {
			if (this.searchVoucher.length == 0) {
				this.openListVoucher();
			} else {
				this.listVoucher = this.listVoucher.filter((code) =>
					code.name.toLowerCase().includes(this.searchVoucher.toLowerCase())
				);
			}
		},
		showDetailPromotion(item) {
			this.detailPromotionDialogVisible = true;
			this.$store.dispatch(FIND_PROMOTION, item.id).then((response) => {
				this.promotionDetail = response.data;
			});
		},
		isWithDrawCourse(studentRegisterCourse, index) {
			return this.withDrawCourses.includes(studentRegisterCourse.course_id)
		},
		openListGift() {
			this.giftDialogVisible = true;
		},
		getListGift() {
			this.listGift = [];
			this.$store.dispatch(LIST_GIFT, {
				'branch_id': this.branchId,
				'quantity_course': this.courses.length,
			}).then((response) => {
				this.listGift = response.data;
				this.listGiftSearch = [...this.listGift];
				this.setGiftSelect(this.listGift);
			});
		},
		pickGift(event) {
			this.dataSearchGift = ''
			this.gift_id.push(event.id);
			this.gift_select = event;
			this.total_need = this.total_need + event.value
			this.unPickPromotions(event);
		},
		cancelGift(item) {
			this.dataSearchGift = ''
			this.gift_select = null
			this.unPickPromotions(item);
		}
	},
	computed: {
		...mapGetters(['currentUser']),
		numberGift() {
			return this.listGift.length;
		},
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.withdraw-course {
	position: absolute;
	right: 20px;
	top: 37px;
}
</style>

<style>
#voucher .el-dialog--center {
	width: 75%;
}

#voucher .el-dialog__body {
	text-align: center;
}

#promotion .el-dialog--center {
	width: 75%;
}

#promotion .el-dialog__body {
	text-align: center;
}

@media (min-width: 1024.5px) {
	#voucher .el-dialog--center {
		width: 40%;
	}


	#promotion .el-dialog--center {
		width: 40%;
	}
}

</style>
